import _, { isNaN } from "lodash";
import { CONST_VALUES } from "../constants/constants";
import { STATUS_COLORS } from "../constants/statuses";
import { PERFORMANCE_DATE_FORMATS } from "../constants/dateFormat";
import moment from "moment";

export const STORAGE_OPTIONS_VALUE = {
  block: "1",
  object: "2",
  file: "3"
};
export const shouldShowIcon = value => {
  let arrayOfvalues = ["online", "enabled"];
  return arrayOfvalues.includes(value);
};

export const checkTruthyValue = value => {
  let arrayOfvalues = ["true"];
  if (!!value) {
    return arrayOfvalues.includes(value.toString().toLowerCase());
  }
  return false;
};

export const showInKiBUnit = value => {
  let arrayOfvalues = ["block size", "capacity", "used", "deduped size"];
  if (!!value) {
    return arrayOfvalues.includes(value.toString().toLowerCase());
  }
  return false;
};

export const isRoleCustomer = role => {
  if (role) {
    return role === CONST_VALUES.CUSTOMER;
  }
  return false;
};

export const isRolePartner = role => {
  if (role) {
    return role === CONST_VALUES.PARTNER;
  }
  return false;
};

export const isRoleIntUser = role => {
  if (role) {
    return role === CONST_VALUES.INTUSER;
  }
  return false;
};

export const roundoff = (num, NaNReturnValue = "NA") => {
  if (isNaN(parseFloat(num))) {
    return NaNReturnValue;
  } else {
    try {
      num = removeCommaFromString(num);
      return parseFloat(num)
        .toFixed(2)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch {
      return NaNReturnValue;
    }
  }
};

const isString = value => {
  return typeof value === "string";
};
const removeCommaFromString = value => {
  return isString(value) ? value.replace(/,/g, "") : value;
};

export const sortByColumnName = (data, columns) => {
  return _.sortBy(data, columns, ["asc", "asc"]);
};

export const getStatusColorCodes = (
  usedColor,
  assetsThreshold,
  percent = 0
) => {
  const criticalLimit = !!assetsThreshold
    ? parseInt(assetsThreshold.Critical)
    : 90;
  const warningLimit = !!assetsThreshold
    ? parseInt(assetsThreshold.Warning)
    : 80;
  let color;
  switch (true) {
    case percent >= criticalLimit:
      color = STATUS_COLORS.CRITICAL;
      break;
    case percent >= warningLimit:
      color = STATUS_COLORS.WARNING;
      break;
    default:
      color = usedColor;
      break;
  }
  return color;
};

export const checkAllLegendsDisable = (legendsArray, showHideLegends) => {
  return (
    legendsArray &&
    legendsArray.length > 0 &&
    legendsArray.every(
      (element, index) =>
        showHideLegends && showHideLegends[legendsArray[index]] === false
    )
  );
};
export const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr?.slice(i, i + chunkSize).join("-");
    res.push(chunk);
  }
  return res;
};

export const breakTextIntoLines = (text, maxWidth) => {
  const words = text?.split("-");
  const lines = [];
  let currentLine = "";
  for (const word of words) {
    const proposedLine = `${currentLine} ${word}`;
    const proposedWidth = proposedLine?.length * 5; // Approximate width calculation based on font size

    if (proposedWidth <= maxWidth) {
      currentLine = proposedLine;
    } else {
      let arrayEle = currentLine?.trim(" ").split(" ");
      lines.push(...sliceIntoChunks(arrayEle, 3));
      currentLine = word;
    }
  }
  let arrayElement = currentLine?.trim(" ").split(" ");
  lines.push(...sliceIntoChunks(arrayElement, 3));
  return lines;
};

export const breakTextIntoLinesPool = (text, maxWidth) => {
  const lines = [];
  let line = "";

  for (let i = 0; i < text.length; i++) {
    line += text[i];
    if (line.length >= maxWidth) {
      lines.push(line);
      line = ""; // Reset line after maxWidth is reached
    }
  }

  if (line) {
    lines.push(line); // Add any remaining text
  }

  return lines;
};

export const isArrayAllNull = arr => {
  return arr?.every(element => element === null) || arr?.length <= 0;
};

export function checkAccessStateCondition(data) {
  let value = data?.toLowerCase();
  return value === "no access" || value === "none" || value === "unknown";
}

export function validateStorageValue() {
  const validOptions = ["overview", "sustainability", "security"];
  return validOptions?.includes(localStorage.getItem("storageOption"));
}

export const getAreaDescription = (data, nullDataLabels, noAccessMsg) => {
  if (data?.hasOwnProperty("message") && noAccessMsg[data?.message]) {
    return noAccessMsg[data?.message].label
      .toLowerCase()
      .split(" ")
      .join("-");
  }
  if (nullDataLabels?.labels?.length === 0) {
    return "all-data";
  }
  if (
    nullDataLabels?.labels?.length === data?.average?.length ||
    nullDataLabels?.labels?.length === data?.used?.length ||
    nullDataLabels?.labels?.length ===
      data?.record?.[1]?.average_display?.length
  ) {
    return "no-data";
  }
  return "partial-data";
};

export function getXAxisLabel(selected) {
  if (selected === "Week") {
    return "Day";
  } else if (selected === "Month") {
    return "Date";
  } else if (selected === "Day") {
    return "Hour";
  } else {
    return "Month";
  }
}

export const calculateTotal = (used = 0, total = 0) => {
  let completed = (used / total) * 100;
  if (completed < 0.9 && completed > 0) {
    completed = 0.9;
  } else if (isNaN(completed)) {
    completed = 0;
  } else {
    completed = (used / total) * 100;
  }
  return completed;
};

export const getErrorMessage = (data = "") => {
  return (
    data?.message ||
    (data.isDataNotPresent && CONST_VALUES.NOT_ENOUGH_DATA_COLLECTED_YET)
  );
};
export const getPerformanceSupportStatus = (siteInfo, selectedAsset) => {
  const selectedAssets = Array.isArray(selectedAsset)
    ? selectedAsset
    : [selectedAsset];
  const filteredInfo = siteInfo.filter(info =>
    selectedAssets?.some(
      asset =>
        info?.site_id === (asset?.siteId || asset?.site_id) &&
        info?.serial_id === (asset?.serialNumber || asset?.serial_id) &&
        info?.product_family_id === asset?.product_family_id
    )
  );
  return filteredInfo.length > 0
    ? filteredInfo[0]?.is_performance_supported
    : false;
};

const calculateStorageData = (
  totalCapacity,
  usedCapacity,
  availableCapacity,
  unit,
  totalLabel,
  usedRateLabel,
  usedLabel,
  availableLabel,
  unitLabel
) => {
  return {
    [totalLabel]: totalCapacity,
    [usedRateLabel]:
      totalCapacity !== 0 ? (usedCapacity / totalCapacity) * 100 : 0,
    [usedLabel]: usedCapacity,
    [availableLabel]: availableCapacity,
    [unitLabel]: unit
  };
};

export const updatePrimaryStorage = storageData => {
  let {
    primary_storage_capacity = 0,
    primary_storage_used_capacity = 0,
    primary_storage_available_capacity = 0,
    s_series_storage_capacity = 0,
    s_series_storage_used_capacity = 0,
    s_series_storage_available_capacity = 0,
    licensed_capacity = 0,
    licensed_used_capacity = 0,
    licensed_available_capacity = 0,
    extended_licensed_capacity = 0,
    extended_used_capacity = 0,
    extended_available_capacity = 0,
    external_storage_capacity = 0,
    external_storage_available_capacity = 0,
    external_storage_used_capacity = 0
  } = storageData || "";

  if (storageData) {
    primary_storage_available_capacity += external_storage_available_capacity;
    primary_storage_capacity += external_storage_capacity;
    primary_storage_used_capacity += external_storage_used_capacity;
  }

  return {
    physicalStorageData: calculateStorageData(
      licensed_capacity,
      licensed_used_capacity,
      licensed_available_capacity,
      CONST_VALUES.TiB,
      "total_physical_storage",
      "physical_used_rate_percentage",
      "physical_used",
      "physical_available",
      "physical_unit"
    ),
    extendedStorageData: calculateStorageData(
      extended_licensed_capacity,
      extended_used_capacity,
      extended_available_capacity,
      CONST_VALUES.TiB,
      "total_extended_storage",
      "extended_used_rate_percentage",
      "extended_used",
      "extended_available",
      "extended_unit"
    ),
    primaryStorageData: calculateStorageData(
      primary_storage_capacity,
      primary_storage_used_capacity,
      primary_storage_available_capacity,
      CONST_VALUES.TiB,
      "total_physical_storage",
      "physical_used_rate_percentage",
      "physical_used",
      "physical_available",
      "physical_unit"
    ),
    s_seriesStorageData: calculateStorageData(
      s_series_storage_capacity,
      s_series_storage_used_capacity,
      s_series_storage_available_capacity,
      CONST_VALUES.TiB,
      "total_extended_storage",
      "extended_used_rate_percentage",
      "extended_used",
      "extended_available",
      "extended_unit"
    )
  };
};

export const formatLabel = (label, selected) => {
  if (selected === "Week") {
    return moment.utc(label).format("D MMM");
  } else if (selected === "Day") {
    return moment(label).format(PERFORMANCE_DATE_FORMATS[selected]);
  } else {
    return moment.utc(label).format(PERFORMANCE_DATE_FORMATS[selected]);
  }
};

export const getformatedLabels = (labels, selected) => {
  return labels.map(label => formatLabel(label, selected));
};

export const modifyInitialData = data => {
  if (Array.isArray(data)) {
    const updatedAssets = data?.map((item, index) => ({
      ...item,
      selected_index: index,
      isChecked: false,
      entitlement_category_ids: []
    }));
    return updatedAssets;
  } else {
    return [];
  }
};

//Function to get selected asset user site information

export const getSelectedAssetUserSiteInfoUtil = (
  selectedAssets,
  userSiteInfo
) => {
  if (!selectedAssets || selectedAssets.length === 0 || !userSiteInfo)
    return [];

  const userSiteInfoKeys = Object.keys(userSiteInfo);
  let selectedClusterKey = [];

  userSiteInfoKeys.forEach(item => {
    selectedAssets.forEach((asset, index) => {
      if (
        (asset?.serialNumber &&
          asset?.serialNumber.toUpperCase() ===
            userSiteInfo[item].serial_id.toUpperCase() &&
          asset?.siteId.toUpperCase() ===
            userSiteInfo[item].site_id.toUpperCase() &&
          asset?.product_family_id === userSiteInfo[item].product_family_id) ||
        (asset?.serial_id &&
          asset?.serial_id.toUpperCase() ===
            userSiteInfo[item].serial_id.toUpperCase() &&
          asset?.site_id.toUpperCase() ===
            userSiteInfo[item].site_id.toUpperCase() &&
          asset?.product_family_id === userSiteInfo[item].product_family_id) ||
        (asset?.serialId &&
          asset?.serialId.toUpperCase() ===
            userSiteInfo[item].serial_id.toUpperCase() &&
          asset?.siteId.toUpperCase() ===
            userSiteInfo[item].site_id.toUpperCase() &&
          asset?.product_family_id === userSiteInfo[item].product_family_id)
      ) {
        selectedClusterKey.push(userSiteInfo[item]);
      }
    });
  });

  return selectedClusterKey;
};

import api from "./api";
import URLS from "../utils/urls.js";
import { CONST_VALUES } from "../constants/constants";
import { STORAGE_OPTIONS_VALUE } from "../utils/commonFuntions.js";

export const getAsset = (serialNumber, siteId, productFamilyId, params) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = siteId;
  newParams["product_family_id"] = productFamilyId;
  params = newParams;
  return api.get(URLS.BACKEND_API + `/api/get_asset_report`, {
    params
  });
};

export const postAsset = asset => {
  return api.post(`/partners/assets/escalated-reports`, { ...asset });
};

export const getAssets = (params, site_info) => {
  const newParams = {};
  if (params.layout === "asset-details") {
    let sites = params["allsites"].replace(/'/g, '"');
    sites = JSON.parse(sites);
    newParams["site_id"] = sites[0];
    newParams["product_category_id"] = params["product_category_id"];
    newParams["serial_id"] = params["serialNumber"];
    newParams["product_family_id"] =
      (site_info && site_info.product_family_id) || params.productFamilyId;
  } else {
    if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
      newParams["favourites"] = true;
    else newParams["site_id"] = params["allsites"];
    newParams["product_category_id"] = params["product_category_id"];
  }
  if (params.productCategoryId !== "2" && params.productCategoryId !== "3") {
    return api.post(URLS.BACKEND_API + `/customers/assets/get_inventory_list`, {
      ...newParams
    });
  } else {
    return api.post(URLS.BACKEND_API + `/customers/assets/get_asset_details`, {
      ...newParams
    });
  }
};

export const getRestrictedAssets = (params, site_info) => {
  // const newParams = {};
  return api.get(URLS.BACKEND_API + `/api/permissions/requested_assets`);
};

export const getRequestAccess = assets => {
  return api.post(URLS.BACKEND_API + `/api/permissions/request_access`, {
    ...assets
  });
};

export const getNotificationRequestAccess = params => {
  return api.get(URLS.BACKEND_API + `/api/permissions/get_notification_count`);
};

export const updateNotification = () => {
  return api.post(URLS.BACKEND_API + `/api/permissions/update_notification`);
};
export const approveRequest = () => {
  return api.post(URLS.BACKEND_API + `/api/permissions/approve_request`);
};

export const denyRequest = assets => {
  return api.post(URLS.BACKEND_API + `/api/permissions/deny_asset_access`, {
    ...assets
  });
};

export const getEntitlementInfo = (params, product_category_id) => {
  const newParams = {};
  if (params.layout === "asset-details") {
    let sites = params["allsites"].replace(/'/g, '"');
    sites = JSON.parse(sites);
    newParams["site_id"] = sites[0];
    newParams["serial_id"] = params["serialNumber"];
    newParams["product_category_id"] = product_category_id;
    newParams["product_family_id"] = params.productFamilyId;
  } else {
    if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
      newParams["favourites"] = true;
    else newParams["site_id"] = params["allsites"];
    newParams["product_category_id"] =
      STORAGE_OPTIONS_VALUE[
        localStorage.getItem("storageOption").toLowerCase()
      ] || "1";
  }
  return api.post(URLS.BACKEND_API + `/get_entitlements_info`, {
    ...newParams
  });
};

export const getAssetsForIntUser = params => {
  let newParams = {};
  let isAssetInfoUrl = window?.location?.hash.indexOf("asset-info") > -1;
  let isSiteInfoUrl = window?.location?.hash.indexOf("site-info") > -1;
  let assetInfoIndex = window?.location?.hash.indexOf("asset-info");
  let siteInfoIndex = window?.location?.hash.indexOf("site-info");
  let serialId = isAssetInfoUrl
    ? window?.location?.hash?.split("/")[assetInfoIndex]
    : window?.location?.hash?.split("/")[siteInfoIndex];
  let siteId = isAssetInfoUrl
    ? window?.location?.hash?.split("/")[assetInfoIndex + 1]
    : window?.location?.hash?.split("/")[siteInfoIndex + 1];

  if (
    (params.layout === "asset-details" || isAssetInfoUrl) &&
    (params?.productCategoryId?.toString() === "2" ||
      params?.productCategoryId?.toString() === "3")
  ) {
    newParams["site_id"] =
      isAssetInfoUrl || isSiteInfoUrl
        ? siteId
          ? siteId?.toUpperCase()
          : "[" +
            String(serialId?.toUpperCase())
              .split(",")
              .map(s => `'${s}'`)
              .join(",") +
            "]"
        : params.allsites.replace(/[^a-zA-Z_0-9]/g, "");
    newParams["serial_id"] =
      isAssetInfoUrl || isSiteInfoUrl
        ? params.serial_id
        : params["serialNumber"];
    newParams["product_family_id"] =
      isAssetInfoUrl || isSiteInfoUrl
        ? params.product_family_id
        : params.productFamilyId;

    return api.post(URLS.BACKEND_API + `/customers/assets/get_asset_details`, {
      ...newParams
    });
  } else {
    if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
      newParams["favourites"] = params["favourites"];
    else {
      newParams["site_id"] =
        params.layout === "asset-details"
          ? params.allsites && params.allsites.replace(/[^a-zA-Z_0-9]/g, "")
          : isAssetInfoUrl
          ? params?.siteId?.toUpperCase()
          : isSiteInfoUrl
          ? "[" +
            String(serialId?.toUpperCase())
              .split(",")
              .map(s => `'${s}'`)
              .join(",") +
            "]"
          : params.allsites;
      if (isAssetInfoUrl || params.layout === "asset-details") {
        newParams["serial_id"] = params["serialNumber"];
        newParams["product_family_id"] = params.productFamilyId;
      }
    }
    if (!isAssetInfoUrl) {
      newParams["product_category_id"] = params.product_category_id;
    }
    return api.post(
      URLS.BACKEND_API + `/api/intuser/assets/get_inventory_list`,
      {
        ...newParams
      }
    );
  }
};

export const getPhysicalPoolCapacity = (
  serialNumber,
  params,
  site,
  site_info
) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = site;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  newParams["unit"] = "TiB";
  return api.post(URLS.BACKEND_API + `/customers/assets/get_capacity_info`, {
    ...newParams
  });
};

export const getStorageCapacity = (serialNumber, site, site_info) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  newParams["site_id"] = site;
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_storage_capacity_data`,
    {
      ...newParams
    }
  );
};
export const getHNASCapacityHistory = (serialNumber, site, site_info) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  newParams["site_id"] = site;
  return api.post(URLS.BACKEND_API + `/customers/assets/get_capacity_history`, {
    ...newParams
  });
};

export const getHNASFileStorageNodeDetails = (
  serialNumber,
  site,
  site_info
) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  newParams["site_id"] = site;
  return api.post(URLS.BACKEND_API + `/assets/get_file_storage_node_details`, {
    ...newParams
  });
};

export const getHNASFileStorageSpanDetails = (
  serialNumber,
  site,
  site_info
) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  newParams["site_id"] = site;
  return api.post(URLS.BACKEND_API + `/assets/get_file_storage_span_details`, {
    ...newParams
  });
};

export const getInternalDriveSummaryInfo = (serialNumber, site, site_info) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  newParams["site_id"] = site;
  newParams["unit"] = "TiB";
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_data_drive_breakdown`,
    {
      ...newParams
    }
  );
};

export const getMetricesDetails = (serialNumber, site, site_info, unit) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  newParams["site_id"] = site;
  newParams["unit"] = unit;
  return api.post(URLS.BACKEND_API + `/customers/assets/get_metrics_details`, {
    ...newParams
  });
};

export const getStorageComponentDetails = (site, serialNumber, site_info) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  newParams["site_id"] = site;
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_storage_component_list`,
    {
      ...newParams
    }
  );
};

export const getNodesSummaryDetails = (site, serialNumber, site_info) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  newParams["site_id"] = site;
  newParams["unit"] = "TiB";
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_nodes_summary_list`,
    {
      ...newParams
    }
  );
};

export const getPhysicalPoolCapacityAnalytics = (
  params,
  site,
  product_category_id
) => {
  const newParams = {};
  if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
    newParams["favourites"] = true;
  else newParams["site_id"] = site;
  newParams["product_category_id"] = product_category_id;
  newParams["unit"] = "TiB";
  return api.post(URLS.BACKEND_API + `/customers/assets/get_capacity_info`, {
    ...newParams
  });
};

export const getLicensedAndInstalledCapacity = (params, site) => {
  const newParams = {};
  if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
    newParams["favourites"] = true;
  else newParams["site_id"] = site;
  newParams["unit"] = "TiB";
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_object_storage_capacity_data`,
    {
      ...newParams
    }
  );
};

export const getFileStorageCapacityAnalytics = (
  params,
  site,
  product_category_id
) => {
  const newParams = {};
  if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
    newParams["favourites"] = true;
  newParams["site_id"] = site;
  newParams["product_category_id"] = product_category_id;
  newParams["unit"] = "TiB";
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_file_storage_capacity_data`,
    {
      ...newParams
    }
  );
};

export const getArrayMicrocodes = (params, site, product_category_id) => {
  const newParams = {};
  if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
    newParams["favourites"] = true;
  else newParams["site_id"] = site;
  newParams["product_category_id"] = product_category_id;
  return api.post(URLS.BACKEND_API + `/customers/assets/get_microcodes_info`, {
    ...newParams
  });
};

export const getDataDriveBreakdown = (params, site, product_category_id) => {
  const newParams = {};
  if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
    newParams["favourites"] = true;
  newParams["site_id"] = site;
  if (product_category_id)
    newParams["product_category_id"] = product_category_id;
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_data_drive_breakdown`,
    { ...newParams }
  );
};

export const getHardwareStatus = (params, site, product_category_id) => {
  const newParams = {};
  newParams["site_id"] = site;
  newParams["product_category_id"] = product_category_id;
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_hardware_status_info`,
    { ...newParams }
  );
};

export const closestToCapacity = (params, site, product_category_id) => {
  const newParams = {};
  if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
    newParams["favourites"] = true;
  else newParams["site_id"] = site;
  newParams["product_category_id"] = product_category_id;
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_closest_to_capacity`,
    { ...newParams }
  );
};

export const getClosestToTemperature = (params, site, product_category_id) => {
  const newParams = {};
  if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
    newParams["favourites"] = true;
  else newParams["site_id"] = site;
  newParams["product_category_id"] = product_category_id;
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_closest_to_temperature`,
    { ...newParams }
  );
};

export const getPowerAndTemperature = (
  serialNumber,
  params,
  site,
  site_info
) => {
  const newParams = {};
  newParams["range"] = params.range;
  newParams["site_id"] = site;
  newParams["serial_id"] = serialNumber;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  return api.get(
    URLS.BACKEND_API + `/customers/assets/get_temperature_power_data`,
    {
      params: newParams
    }
  );
};
export const getHNASPowerAndTemprature = (
  serialNumber,
  params,
  site,
  site_info
) => {
  const newParams = {};
  newParams["range"] = params.range;
  newParams["site_id"] = site;
  newParams["serial_id"] = serialNumber;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  return api.get(
    URLS.BACKEND_API + `/customers/assets/get_temperature_power_data`,
    {
      params: newParams
    }
  );
};

export const getStorageTrend = (serialNumber, params, site, site_info) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = site;
  newParams["range"] = params.range;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  return api.get(URLS.BACKEND_API + `/customers/assets/get_capacity_trend`, {
    params: newParams
  });
};

export const getUsedAllocatedCapcity = (
  serialNumber,
  params,
  site,
  site_info
) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = site;
  newParams["range"] = params.range;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  return api.get(
    URLS.BACKEND_API + `/customers/assets/get_used_allocated_free_data`,
    { params: newParams }
  );
};

export const getSoftwareSavings = (serialNumber, params, site, site_info) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = site;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  return api.post(URLS.BACKEND_API + `/customers/assets/get_adr_savings`, {
    ...newParams
  });
};

export const getDiskDriveBreakdown = (
  serialNumber,
  params,
  site,
  product_category_id,
  site_info
) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = site;
  newParams["product_category_id"] = product_category_id;
  newParams["product_family_id"] = site_info && site_info.product_family_id;
  return api.post(
    URLS.BACKEND_API + `/customers/assets/get_data_drive_breakdown`,
    { ...newParams }
  );
};

export const getAssetGraph = (serialNumber, params, site_info) => {
  params["site_id"] = params.site;
  params["serial_id"] = serialNumber;
  params["product_family_id"] = site_info && site_info.product_family_id;
  delete params.site;
  return api.get(
    URLS.BACKEND_API + `/customers/assets/get_temperature_power_data/chart`,
    {
      params
    }
  );
};

export const getAssetGraphForCapacityTrend = (
  serialNumber,
  params,
  site_info
) => {
  const newparam = {};
  newparam["serial_id"] = serialNumber;
  newparam["site_id"] = params.site;
  newparam["range"] = params.range;
  newparam["product_family_id"] = site_info && site_info.product_family_id;
  params = newparam;
  return api.get(URLS.BACKEND_API + `/customers/assets/get_capacity_trend`, {
    params
  });
};

export const getAssetGraphForUsedAvailableCapacity = (
  serialNumber,
  params,
  site_info
) => {
  const newparam = {};
  newparam["serial_id"] = serialNumber;
  newparam["site_id"] = params.site;
  newparam["range"] = params.range;
  newparam["product_family_id"] = site_info && site_info.product_family_id;
  params = newparam;
  return api.get(
    URLS.BACKEND_API + `/customers/assets/get_used_allocated_free_data`,
    { params }
  );
};

export const getAnalytics = params => {
  return api.get(URLS.BACKEND_API + `/customers/analytics`, { params });
};

export const patchAsset = (serialNumber, asset) => {
  return api.patch(URLS.BACKEND_API + `/customers/assets/${serialNumber}`, {
    ...asset
  });
};

export const removeAssets = assets => {
  return api.post(URLS.BACKEND_API + `/api/intuser/assets/remove_assets`, {
    ...assets
  });
};

export const addAssets = assets => {
  return api.post(URLS.BACKEND_API + `/api/intuser/assets/add_assets`, {
    ...assets
  });
};

export const getAssetsReportCSV = assets => {
  return api.post(URLS.BACKEND_API + `/assets/initiate_csv_generation`, {
    ...assets
  });
};
export const getLunHostGroupDetails = payload => {
  const { serialNumber, siteId, product_family_id } = payload;
  if (serialNumber && siteId && product_family_id)
    return api.get(
      URLS.BACKEND_API +
        `/assets/get_lun_host_group_details?serial_id=${serialNumber}&site_id=${siteId}&product_family_id=${product_family_id}`
    );
};

export const getAssetGraphForVolumeIOPs = (serialNumber, params) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = params.site;
  newParams["product_family_id"] = params.productFamilyId;
  newParams["range"] = params.range;
  params = newParams;
  return api.post(URLS.BACKEND_API + `/assets/get_volume_iops`, {
    ...params
  });
};

export const getAssetGraphForVolumeResponseTime = (serialNumber, params) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = params.site;
  newParams["product_family_id"] = params.productFamilyId;
  newParams["range"] = params.range;
  params = newParams;
  params = newParams;
  return api.post(URLS.BACKEND_API + `/assets/get_volume_response_time`, {
    ...params
  });
};

export const getAssetGraphForVolumeTransferRate = (serialNumber, params) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = params.site;
  newParams["product_family_id"] = params.productFamilyId;
  newParams["range"] = params.range;
  params = newParams;
  return api.post(URLS.BACKEND_API + `/assets/get_volume_transfer_rate`, {
    ...params
  });
};

export const getAssetGraphForProcessorBusyRate = (serialNumber, params) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = params.site;
  newParams["product_family_id"] = params.productFamilyId;
  newParams["range"] = params.range;
  params = newParams;
  return api.post(URLS.BACKEND_API + `/assets/get_processor_busy_rate`, {
    ...params
  });
};

export const getAssetGraphFoOperationPerSecond = params => {
  const serial_id = params.serialNumber;
  const site_id = params.site;
  const product_family_id = params.productFamilyId;
  const range = params.range;
  return api.get(
    URLS.BACKEND_API +
      `/assets/get_operation_per_second_trend?serial_id=${serial_id}&site_id=${site_id}&product_family_id=${product_family_id}&range=${range}`
  );
};

export const getAssetGraphForServerUtilization = params => {
  const serial_id = params.serialNumber;
  const site_id = params.site;
  const product_family_id = params.productFamilyId;
  const range = params.range;
  return api.get(
    URLS.BACKEND_API +
      `/assets/get_server_utilization_trend?serial_id=${serial_id}&site_id=${site_id}&product_family_id=${product_family_id}&range=${range}`
  );
};

export const getAssetGraphForDiskLatency = params => {
  const serial_id = params.serialNumber;
  const site_id = params.site;
  const product_family_id = params.productFamilyId;
  const range = params.range;
  return api.get(
    URLS.BACKEND_API +
      `/assets/get_disk_latency_trend?serial_id=${serial_id}&site_id=${site_id}&product_family_id=${product_family_id}&range=${range}`
  );
};

export const getAssetGraphForEthernetThroughput = params => {
  const serial_id = params.serialNumber;
  const site_id = params.site;
  const product_family_id = params.productFamilyId;
  const range = params.range;
  return api.get(
    URLS.BACKEND_API +
      `/assets/get_ethernet_throughput_trend?serial_id=${serial_id}&site_id=${site_id}&product_family_id=${product_family_id}&range=${range}`
  );
};

export const getAssetGraphForCacheUsageRate = (serialNumber, params) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = params.site;
  newParams["product_family_id"] = params.productFamilyId;
  newParams["range"] = params.range;
  params = newParams;
  return api.post(URLS.BACKEND_API + `/assets/get_cache_usage_rate`, {
    ...params
  });
};

export const getAssetGraphForCacheWritePendingRate = (serialNumber, params) => {
  const newParams = {};
  newParams["serial_id"] = serialNumber;
  newParams["site_id"] = params.site;
  newParams["product_family_id"] = params.productFamilyId;
  newParams["range"] = params.range;
  params = newParams;
  return api.post(URLS.BACKEND_API + `/assets/get_cache_write_pending_rate`, {
    ...params
  });
};

export const getCloudConnection = params => {
  return api.get(
    URLS.BACKEND_API +
      `/olympus/get_orgdevice_details/${params.serialNumber}/${params.siteId}/${params.productFamilyId}`
  );
};

export const resetCloudConnection = params => {
  const newParams = {};
  const serial_id = params.serial_id;
  const site_id = params.site_id;
  newParams["refresh"] = params.refresh;
  params = newParams;
  return api.get(
    URLS.BACKEND_API + `/olympus/get_orgdevice_details/${serial_id}/${site_id}`,
    { params }
  );
};

export const getOrgDetails = params => {
  return api.get(
    URLS.BACKEND_API +
      `/olympus/get_org_id/${params.serialNumber}/${params.siteId}`
  );
};

export const getCredentialsDetails = params => {
  return api.get(
    URLS.BACKEND_API +
      `/apigateway/get_credentials?${`is_win_closed=${params}`}`
  );
};

export const createCredentialsDetails = payload => {
  const { params } = payload;
  return api.post(URLS.BACKEND_API + `/apigateway/create_credentials`, {
    ...params
  });
};

export const getApiGetwayInfo = payload => {
  return api.get(URLS.BACKEND_API + `/apigateway/get_api_gateway_info`);
};
export const updateWhitelistIp = payload => {
  const { params } = payload;
  return api.put(URLS.BACKEND_API + `/apigateway/update_credentials`, {
    ...params
  });
};

export const getPerformanceApproval = params => {
  const newParams = {};
  const serial_id = params.serialNumber;
  const site_id = params.siteId;
  newParams["device_status"] = params.approvalStatus;
  params = newParams;
  return api.get(
    URLS.BACKEND_API + `/olympus/device_approval/${serial_id}/${site_id}`,
    { params }
  );
};

export const saveUserPreference = params => {
  return api.put(
    URLS.BACKEND_API + `/customers/assets/user_notification_preference`,
    {
      ...params
    }
  );
};
export const getUserPreference = () => {
  return api.get(
    URLS.BACKEND_API + "/customers/assets/user_notification_preference"
  );
};

export const updateFavoriteAsset = params => {
  return api.put(URLS.BACKEND_API + `/update_favourite_asset`, {
    ...params
  });
};
